@import "variables";
@import "./grid/grid";

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .ekko-lightbox .modal-dialog {
    flex: 100% !important;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    -ms-overflow-style: none;
  }
}

// [изм.10974 н.0000189466]
.textarea-resize-none {
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
}

//.b24-window-panel { /*220198 и.12541*/
//  max-width: 520px !important;
//}

.modal_personal_data { /*220198 и.12541*/
  #accept {
    background: rgba(0, 174, 239, 1);
    border: none;
    height: 52px;
    font-weight: 700;

    &:hover {
      background-color: rgba(0, 174, 239, 0.7);
    }
  }

  #not-accept {
    height: 52px;
    border: 1px solid rgba(0, 0, 0, .5);
    background: none;
    color: #000;
    font-weight: 700;
  }

  .modal-footer {
    > .row {
      margin-right: 0;
      margin-left: 0;

      > .col-xs-6 {
        padding: 0;

        &:last-child {
          padding-left: 10px;
        }
      }
    }
  }
}

.b24-window-panel {
  max-width: 520px !important;
}