html, body{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #000000;
    /* O: 214859 */
    /*margin: 0;*/
    /*padding: 0 !important;*/ /*н 68059*/
    /*position: relative;*/
    z-index: 0;
    zoom: 1;
    /*н 68059:min-width: 800px;*/
}

html {
    height: 100%;                   /* O: 214859 */
    position: relative;             /* O: 214859 */
    min-height: 100%;               /* O: 214859 */

    display: table; /*206366 и.12183*/
    width: 100%; /*206366 и.12183*/
}

body {
    position: static;    /* O: 214859 */
    margin: 0 0 44px 0;  /* O: 214859 *//*206420 и.12356*/
    min-height: 100%;
    min-width: 800px; /*н 74002*/
}

h2, h3{
    margin: 0;
}

a {
    font-family:Arial, Helvetica, sans-serif;
    font-size: inherit; /*206366 и.12183*/
    text-decoration:underline;
}

a:link, a:active, a:visited {
    color:#003399;
}

a:hover {
    color:#FF6600;
}

.topMenu {
    min-height: 20px;
    padding: 0;
    margin: 3px 0; /* н 66988: 0->3px */
    min-width: 800px;
    z-index: 999;
}
.topMenu0 {
    position: fixed;
    outline: 0 none white;
    left: 0;
    top: 0;
    right: 0;
    min-height: 44px; /* н 66988: 30px->44px */
    /*max-height: 44px;*/
    padding: 0;
    margin: 0;
    min-width: 800px;
    /*width:100%;*/
    width: auto; /*206366 и.12183*/
    z-index: 998;
    background-color: #1b4f88;
    background-image: -moz-linear-gradient(top, #58b 0, #1b4f88 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #58b), color-stop(100%, #1b4f88));
    background-image: -webkit-linear-gradient(top, #58b 0, #1b4f88 100%);
    background-image: -o-linear-gradient(top, #58b 0, #1b4f88 100%);
    background-image: -ms-linear-gradient(top, #58b 0, #1b4f88 100%);
    background-image: linear-gradient(top, #58b 0, #1b4f88 100%);
    -pie-background: linear-gradient(#58b, #1b4f88);
    /*behavior: url(/design/css/PIE/PIE.php);*/
}

.topMenu .tmLeft {
    float: left;
    position: relative;
    z-index: 1000;
    padding-top: 3px;
    z-index: 2000 !important; /*206366 и.12183*/
}

.topMenu .tmLeft > a, .topMenu .tmLeft span.activePage, .topMenu .tmLeft > img/* н 66988 */ {
    display: block;
    float: left;
}

.topMenu .tmRight {
    float: right;
    position: relative;
    z-index: 1000;
    padding-top: 3px;/* 60954 */
}

.topMenu .tmLeft, .topMenu .tmRight {
    margin-bottom: 4px; /*206366 и.12183*/
}

*:first-child+html .topMenu .tmRight {
    height: 39px;
}

*:first-child+html .topMenu .tmRight table {
    width: 542px;
}

.topMenu .tmRight strong {
    height: 18px;
    padding: 6px;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    vertical-align: middle;
    zoom: 1;
    border: none;
}

.topMenu .tmRight > a, .topMenu .tmRight span.menuSep  {
    display: block;
    float:right;
}
/* н 66988 */
.topMenu .tmLeft > a:not(:first-child):link, .topMenu .tmRight > a:link,
.topMenu .tmLeft > a:not(:first-child):visited, .topMenu .tmRight > a:visited,
.topMenu span.menuSep {
    color:#ffffff;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.topMenu .tmLeft > a[href^="/"], .topMenu .tmRight > a[href^="/"], .topMenu .activePage {
    color: #FFFFFF;
    margin: 0 4px; /*206366 и.12183*/
}

.topMenu > div > a,
.topMenu span.menuSep, .topMenu span.activePage {
    font-size: 10pt;
    font-family: Arial, sans-serif;
    text-decoration:none;
    padding: 6px;
    height:18px;
    font-weight: bold;
    display: block;
}
/* н 66988 */
/*.topMenu .tmLeft > a:not(:first-child):hover, .topMenu .tmRight > a:not(:first-child):hover,
.topMenu .tmLeft > a.xhover,
.topMenu span.activePage {*/
.topMenu .tmLeft > a[href^="/"]:hover, .topMenu .tmRight > a[href^="/"]:hover, .topMenu span.activePage {
    color:#ffffff;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    background-color: #8bbff8;
    background-image: -moz-linear-gradient(top, #58b 0, #8bbff8 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #58b), color-stop(100%, #8bbff8));
    background-image: -webkit-linear-gradient(top, #58b 0, #8bbff8 100%);
    background-image: -o-linear-gradient(top, #58b 0, #8bbff8 100%);
    background-image: -ms-linear-gradient(top, #58b 0, #8bbff8 100%);
    background-image: linear-gradient(top, #58b 0, #8bbff8 100%);
    -pie-background: linear-gradient(#58b, #8bbff8);
    text-decoration:none;
    behavior: url(/design/css/PIE/PIE.php);
}

/*69954*/
.topMenu .tmRight a[href="/cart"] {
    font-size: 16px;
}

.topMenu .tmRight a[href="/cart"]:hover {
    background-color: inherit;
    background-image: inherit;
}
/*end 69954*/

.topMenu .tmRight a.menu:first-child { /* н 66988 */
    font-weight: normal;
    border-left: 1px dotted #F60;
    padding: 0;
    width: 23px;
    height: 29px;
    margin-right: 5px;
}
.topMenu .tmRight > a.menu:first-child > img  { /* н 66988 */
    margin: 3px 0 3px 0;
}


.topMenu .tmRight > .bootstrap-select > button{
    padding: 2px 0 2px 15px;
    margin-top: 2px;
}

.topMenu .tmRight > .bootstrap-select {
    right: 10px;
}

.topSubMenu a, .topSubMenu a:visited {
    background:#ffffff;
    color:#000000;
    padding: 2px;
    display: block;
    text-decoration:underline;
}

.topSubMenu a:hover, .topSubMenu span.link span {
    background:#ffffff;
    color:#ff6600;
    padding: 2px;
    display: block;
    text-decoration:underline;
}

#KOD_USER {
    border-radius: 0px 0px 4px 4px;
    font-size: 14px;
    padding:5px 5px 5px 5px;
    color: black;
    position: absolute;
    right: 0;
    z-index: 32;
}

/* н 66988 */
#banner {
    border: solid 1px #8bbff8;
    margin: 0 5px 0 10px;
}
/* н 66988 */
.topMenu .tmLeft > a:first-child {
    margin: auto 8px;
}

.gray-filling {
    padding: 5px !important;
    padding-left: 10px !important;
    background-color: #E5E5E5 !important;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -khtml-border-radius: 8px;
    border-radius: 8px;
    behavior: url(/design/css/PIE/PIE.php);
    font-size: 15px;
    margin-bottom: 5px;
    text-decoration: none !important;
}

.amContainer {
    position:relative;
    display: block;
    float:left;
}

.topSubMenu {
    display: none;
    position:absolute;
    left:   0;
    top:    30px;
    width:  300px;
    padding: 0 5px 5px 5px;

    z-index:1000;
    background: #ffffff;
    -moz-box-shadow: -2px 2px 2px rgba(0,0,0,.4);
    -webkit-box-shadow: 1px 2px 4px rgba(0,0,0,.4);
    box-shadow: 1px 2px 4px rgba(0,0,0,.4);
    behavior: url(/design/css/PIE/PIE.php);
}

.topSubMenu span.comment {
    display: block;
    color:#666;
    font-size:1.1em;
    padding: 5px;
    margin: 5px -4px;
    text-align: left;
    font-weight: bold;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    behavior: url(/design/css/PIE/PIE.php);

}

.topSubMenu span.comment:first-child {
    margin-top: 0;
}

#formLF table {
    border-collapse: inherit;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
}

#formLF table td {
    padding: 0px 3px; /* н 66988: 3px->0px */
}

#formLF table label[for=iRemember] {
    font-weight: normal;
    margin-bottom: 0;
}

#formLF #log_p {
    padding-right: 20px;
}

#formLF .input-group {
    width: 170px;
}

#formLF .btn-toggle-password-visibility {
    display: inline-block;
    font-size: 11px;
    margin-left: -16px;
    text-decoration: none;
}

#formLF .btn-toggle-password-visibility:focus {
    outline: 0;
}

div.managerBlock {
    box-sizing: content-box;
    margin-right: 10px;
    display: inline-block;
    padding: 6px;/* 60954 */
    min-width: 200px;
    background-color: #BADEFF;/* 60954 */
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -khtml-border-radius: 8px;
    border-radius: 8px;
    border: solid 1px #BADEFF;/* 60954 */
    behavior: url(/design/css/PIE/PIE.php);
}

div.managerBlockAuth, div.managerBlockAuth a {
    font-size: 14px;
    line-height: 16px;
    min-height: 16px;
    margin: 1px 0;
}

#managerBlockDetails > div.managerBlockAuth {
    width: calc( 100% - 37px );
}

div.managerBlockAuth a {
    text-decoration: none;
    border-bottom: 1px dotted #000;font-size: 14px;
}

div.managerBlockNoAuth {
    padding-right: 25px;
    font-size: 14px;
}

div.managerBlockNoAuth a {
    font-size: 14px;
}

div.managerBlockDetails {
    line-height: normal;
    box-sizing: content-box;
    position: fixed;
    top: 45px;
    right: 3px;
    z-index: 33;
    display: none;
    background-color: #EEEEEE;
    -webkit-box-shadow: 0px 0px 15px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 15px rgba(0,0,0,0.5);
    box-shadow: 0 0 15px rgba(0,0,0,0.5);
    border:solid 1px rgba(0,0,0,0.5);
    margin-right: 0 !important;
}

div.managerBlockDetails hr {
    margin: 6px 0;
    border-style: inset;
    border-width: 1px;
}

div#managerInfo {
    width: auto;
    overflow: auto;
    display: inline-block;
}

div#managerInfo table td, #managerTableInfo table td { /*206366 и.12183*/
    padding: 5px;
}

div#managerInfo table td.nameManager {
    font-size: 20px;
}

div#managerTablesInfo {
    margin: 10px 0 0 0;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden; /* P: 2440 an.gerasimov 18.09.16 */
    max-height: 510px;
}

div#managerTableInfo {
    display: inline-block;
}

table.managerContacts a.link {
    font-size:18px;
}

table.managerContacts b.dob {
    font-size:18px;
}

div#managerCommentWork {
    font-size:12px;
    margin: 10px 0 5px 0;
    min-height: 28px;
}

div#managerCommentWork a {
    font-size:12px;
}

div#managerCommentWork a:hover {
    cursor: pointer;
}

a.managerBlockClose {
    display: none;
    position: absolute;
    right: 15px;
    width: 25px;
    height: 25px;
    background-image: url('/design/img/close-default.png');
}

a.managerBlockClose:hover{
    background-image: url('/design/img/close-hover.png');
}


#footer{
    height: 44px;
    background-color: #1b4f88;
    background-image: -moz-linear-gradient(top, #58b 0, #1b4f88 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #58b), color-stop(100%, #1b4f88));
    background-image: -webkit-linear-gradient(top, #58b 0, #1b4f88 100%);
    background-image: -o-linear-gradient(top, #58b 0, #1b4f88 100%);
    background-image: -ms-linear-gradient(top, #58b 0, #1b4f88 100%);
    background-image: linear-gradient(top, #58b 0, #1b4f88 100%);
    -pie-background: linear-gradient(#58b, #1b4f88);
    /*behavior: url(/design/css/PIE/PIE.php);*/
    width: 100%;
    color: #ffffff;
    position: absolute;
    bottom: 0;
}

#live{
    /*н 81902:width: 600px;*/
    margin:0 auto;
}

#live img{
    margin-top:5px;
    margin-right:5px;
    vertical-align: middle;
}

#footer-elements{
    text-align: center;
    width: 100%;
    position: absolute;
    color: #ffffff;
}

#copyright{
    /*[206420 и.12356]
    text-align: center;
    width: 100%;
    bottom: 10px;
    position: relative;
    top: 5px;*/
    color: #ffffff;
}

#copyright > a, #copyright > a:hover{
    color: #ffffff;
}

#footer-elements .row { /*206420 и.12356*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    padding: 12px 35px;
}

#footer-elements .col { /*206420 и.12356*/
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 15px;
}

/*#footer-elements .col:nth-child(1) { !*206420 и.12356*!*/
/*    justify-content: flex-start;*/
/*}*/

/*#footer-elements .col:nth-child(2) { !*206420 и.12356*!*/
/*    justify-content: center;*/
/*}*/

/*#footer-elements .col:nth-child(3) { !*206420 и.12356*!*/
/*    justify-content: flex-end;*/
/*}*/

#footer ::before, #footer ::after {
    content: none !important;
}


/* н 90477*/
#header {
    margin: 0px 12px;
    width: 95%;
    border-collapse: collapse;
    border-spacing: 0px;
}

div.message {
    position: relative;
    background-color: #FFF7C6;
    padding: 6px;
    margin-bottom: 4px;
    font-family: Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    color: #000;
    line-height: 16px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -khtml-border-radius:5px;
    border-radius: 5px;
    behavior: url(/design/css/PIE/PIE.php);
}

div.search-new { /*92181 1511*/
    background-color: #eef6ff;
    background-image: -moz-linear-gradient(top, #eaf4ff 0, #d9ebff 100%) !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #eaf4ff), color-stop(100%, #d9ebff)) !important;
    background-image: -webkit-linear-gradient(top, #eaf4ff 0, #d9ebff 100%) !important;
    background-image: -o-linear-gradient(top, #eaf4ff 0, #d9ebff 100%) !important;
    background-image: -ms-linear-gradient(top, #eaf4ff 0, #d9ebff 100%) !important;
    background-image: linear-gradient(top, #eaf4ff 0, #d9ebff 100%) !important;
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eaf4ff', endColorstr='#d9ebff') !important;*/
    -pie-background: linear-gradient(#eaf4ff, #d9ebff) !important;
    behavior: url(/design/css/PIE/PIE.php);
}

div.message a {
    font-size: 1.0em;
}

#header div.message { /* 66988 */
    margin-bottom: 10px;
}

#td {
    padding: 0 0 60px !important;
}

a.currentGroupLink:link, a.currentGroupLink:visited, a.currentGroupLink:active, a.currentGroupLink:hover {
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 5px;
    background-color:#48FF6C;
}

a.currentGroupLink:link, a.currentGroupLink:visited {
    color:#000000;
}

a.currentGroupLink:active, a.currentGroupLink:hover {
    background-color:#8AFFA2;
    color:#000000;
}

.userField {
    font: normal 14px Arial;
}

select.userField {
    background-color: #4d7bad;
    color: #eaf4fe;
}

select.userField:disabled { /*н 94808*/
    color: rgb(84,84,84);
    background-color: rgb(235, 235, 228);
    cursor: not-allowed;
}

div.topTable {
    height: 44px; /* н 66988: 30px->44px */
    min-width: 800px;
    height: 100%; /*206366 и.12183*/
}

table.topTable {
    margin-top: 10px; /* н 66988: 5px->10px */
    border-collapse: collapse;

}

table.topTable td {
    margin: 0;
    padding: 0;
}

table#header, table#header * { /* н 66988 */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fieldName{
    width:50%;
    background-color:#F7F7F7;
    border-bottom:1px solid #8d8d8d;
    text-align: left;
}

.fieldHead{
    width:40%;
    background-color:#ffffff;
    border-bottom:1px solid #cecece;
    color:#999999;
    font-weight:bold;
    font-size:1.2em;
}

.fieldName2{
    border-bottom:1px solid #8d8d8d;
    text-align: left;
}

.fieldLinks{
    padding:15px 10px 15px 10px;
    border-bottom:1px solid  #E4E4E4;
}

.user_profile{
    background-color: #F2F2F2 !important;
    border: 1px solid #C0C0C0;
    color: black !important;
    font-family: arial;
    font-size: 20px;
    font-weight: bold;
    /*height: 20px;*/
    padding: 4px;
    /*margin-top: 4px;
    margin-bottom:4px;*/
    margin-top: 3px !important;
    margin-bottom: 3px !important;
    vertical-align: middle;
}

select.user_profile{
    height: 26px;
    margin: 3px;
}

.topTable td {
    padding-bottom:40px;
}

#back-top {
    position: fixed;
    margin: 0; /*206420 и.12356*/
    bottom: 50px;
    right: 10px;
    width: 54px;
    height: 64px; /*206420 и.12356*/
    z-index: 1;
}

.b24-widget-button-position-bottom-right, #back-top {
    bottom: 60px !important;
}

*:first-child+html #back-top {
    margin-bottom: 15px;
}

#back-top a {
    width: 54px;
    display: block;
    text-align: center;
    font: 11px/100% Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    color: #bbb;

    /* transition */
    -webkit-transition: 1s;
    -moz-transition: 1s;
    transition: 1s;
    z-index: 1;
}
#back-top a:hover {
    color: #000;
}

/* arrow icon (span tag) */
#back-top span {
    width: 54px;
    height: 54px;
    display: block;
    margin-bottom: 7px;
    background: #ddd url('/design/img/up-arrow.png') no-repeat center center;

    /* rounded corners */
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -khtml-border-radius:15px;
    border-radius: 15px;

    /* transition */
    -webkit-transition: 1s;
    -moz-transition: 1s;
    transition: 1s;
    z-index: 1;
    behavior: url(/design/css/PIE/PIE.php);
}

#back-top a:hover span {
    background-color: #777;
}

.loginField{
    font-size: 12px;
    width:170px;
    height:20px;
    border:none;
    font-family: Arial, sans-serif;
    padding-left: 2px;
    color: #000000;
}

.button-primary {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-image: none;
    border-style: solid;
    border-width: 1px;

    background-image: linear-gradient(to bottom, #428BCA 0px, #2D6CA2 100%);
    background-repeat: repeat-x;
    border-color: #2B669A;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15) inset, 0 1px 1px rgba(0, 0, 0, 0.075);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
    background-color: #428BCA;
    color: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857;
    margin-bottom: 0;
    padding: 6px 12px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
}

.button-primary:active {
    background-color: #2D6CA2;
    border-color: #2B669A;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.125) inset;
}

.button-primary:hover, .button-primary:focus {
    background-color: #2D6CA2;
    background-position: 0 -15px;
}

a.pageLink:link, a.pageLink:visited, a.pageLink:active, a.pageLink:hover {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    text-decoration:underline;
    display: block;
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 5px;
    background-color:#f1f1f1;
    border-style:none;
    border-width:1px;

}

a.pageLink:link, a.pageLink:visited {
    color:#003399;
}

a.pageLink:active, a.pageLink:hover {
    background-color:#FFCC00;
    color:#000000;
}

.comment.table_space {
    line-height: 30px;
    margin-top: 5px;
}

.comment, .comment td{
    color:#999999;
    font-size:12px;
}

.comment2{
    color:#999999;
    font-size:14px;
}

.tabadv1, .tabadv1 td  {
    border-bottom:1px solid #8d8d8d;
}
.tabadv2, .tabadv2 td  {
    border-bottom:1px solid #cecece;
}

#found {
    display: none;
}

#ajax-loader > img{
    vertical-align: middle;
    text-align: center;
}

table#table_sending_list{
    border: 1px solid #999999;
    border-collapse: collapse;
}

table#table_sending_list tr{
    border: 1px solid #999999;
}

table#table_sending_list tr td{
    border: 1px solid #999999;
}

#search-loader, .search-loader, #pagination-loader { /*103464 0196*/
    background: url(/build/images/ajax-loader2.532b28bc.gif) left top no-repeat;
    color: #000;
    display: inline-block;
    display: -moz-inline-stack;
    vertical-align: middle;
    zoom: 1;
    padding: 6px 0 0 40px;
    height: 32px;
    margin: 0 auto;
    position: relative;
    left: 50%;
    margin-left: -70px;
}

* html .g-png24 {
    behaviour:expression(
        (!this.fixedPNG?
        (function(el){
        var fixSrc = "", sizingMethod = "crop";
    if (el.tagName.toLowerCase() == "img") {
    fixSrc = el.src;
    sizingMethod = "image";

    el.style.width = 1;
    el.style.height = 1;
    el.src = "";
}
else {
    var tmpImg = new Image();
    tmpImg.src = el.currentStyle.backgroundImage.split('\"')[1];
    if (parseInt(tmpImg.width) == 1 || parseInt(tmpImg.height) == 1 || el.className.indexOf('g-png-24__scaled') > -1) {
    sizingMethod = "scale";
}

fixSrc = el.currentStyle.backgroundImage.split('\"')[1];
el.className += " g-png-fixed";
}
el.runtimeStyle.filter = "progid:DXImageTransform.Microsoft.AlphaImageLoader(src=" + fixSrc + ", sizingMethod='" + sizingMethod + "')";
el.fixedPNG = true;
})(this):'')
);
}

.dotted-blue-link {
    display: inline-block;
    color: #003399;
    text-decoration: none;
    border-bottom: 1px dotted #003399;
    cursor: pointer;
    line-height: 15px;
}

.dotted-blue-link:hover {
    color: #FF6600;
    border-bottom: 1px dotted #FF6600;
    text-decoration: none;
    line-height: 15px;
}

.solid-blue-link { /*117834 5058*/
    display: inline-block;
    color: #003399;
    /*text-decoration: none;*/
    /*border-bottom: 1px solid #003399;*/
    text-decoration: underline !important;
    cursor: pointer;
    line-height: 15px;
    font-size: inherit;
}

.solid-blue-link:hover { /*117834 5058*/
    color: #FF6600 !important;
    /*border-bottom: solid 1px #FF6600;*/
    /*text-decoration: none;*/
    line-height: 15px;
}

.solid-blue-link:focus { /*117834 5058*/
    color: #003399;
    /*text-decoration: none;*/
    /*border-bottom: 1px solid #003399;*/
}

#dl-date-from, #dl-date-to {
    padding-left: 6px;
    margin-bottom: 0;
    height: 30px;
    padding-top: 4px\0/;
    padding-left: 8px\0/;
    width: 100px;
}

.fForms{
    font-size:12px;
    border-collapse: separate !important;
}

#tabs {
    font-size: 11px;
    display: none;
}

.sort-asc{
    background-image: url(/build/images/asc.c6838ad6.gif);
    background-repeat: no-repeat;
    background-position: center right;
    cursor: pointer;
    min-width: 110px; /*Изм.8466 н.0000154376*/
}

.sort-desc{
    background-image: url(/build/images/desc.f70e8e1c.gif);
    background-repeat: no-repeat;
    background-position: center right;
    cursor: pointer;
    min-width: 110px; /*Изм.8466 н.0000154376*/
}

/* profile styles :start */

.profile-block { width: 100%; }
.profile-block>.title { margin: 5px 0 5px 0; padding: 0; }
.profile-block>.title>a {
    display: block;
    background: url(/build/images/down.9f2a8ffa.png) left top no-repeat;
    color: #000;
    font-size: 1.2em;
    padding-left: 30px;
    text-decoration: underline;
    height: 30px;
}
.profile-block>.title>a:hover {
    color: #ff8400;
}
.profile-block>.content { margin: 0; padding: 0; }
.profile-block table { width: 100%; }

.profile-block .listFilial {
    display: none;
    max-width: 250px;
    margin: 5px 20px 0;
    padding: 10px;
    background-color: #FFF7C6;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -khtml-border-radius: 5px;
    border-radius: 5px;
    behavior: url(/design/css/PIE/PIE.php);
}

.profile-block .photos_without_watermark {
    display: none;
    max-width: 250px;
    margin: 5px 20px 0;
    padding: 10px;
    background-color: #FFF7C6;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -khtml-border-radius: 5px;
    border-radius: 5px;
    behavior: url(/design/css/PIE/PIE.php);
}

#profile-errors { position: relative; display: block; }

#profile-help {
    margin: 4px 10px 0 0;
    padding: 7px;
    background-color: #FFF7C6;
    min-width: 150px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -khtml-border-radius: 5px;
    border-radius: 5px;
    behavior: url(/design/css/PIE/PIE.php);
    position: relative;
    z-index: 0;
}

#reg_form {
    margin-bottom:1.5em;
    text-align:left;
}

#reg_form input {
    font-size:1.5em;
}

/* profile styles :end */

#checkUserFIOModal .alert-message-modal, #checkUserTelFedModal .alert-message-modal {
    background-color: #A6D6FF;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 15px;
    text-align: center;
}

#formReg #step-2 > .row > div, #formReg #step-1 > .row > div, #step-3, .forgotPassForm {
    background-color: #ECECEC;
    border-radius: 30px;
    box-shadow: 5px 5px 10px #919191;
}

.alert-message {
    border-color: #F5E79E !important;
    background-color: #FFFFFF;
    color: #5d5d5d;
}

.alert {
    font-size:14px;
}

.container .row a, #step-3 .row a {
    color: #003399;
    text-decoration: underline;
    font-size: 14px;
}

.alert-ManagerBlock {
    background-color: #FFFFFF;
    border: 1px solid #F5E79E !important;
    border-radius: 20px !important;
    color: #5D5D5D;
    margin-bottom: 20px;
    margin-right: 5px;
    padding: 4px;
    text-align: left;
}

.alert-ManagerBlock .table { /*166982 и.9108*/
    margin-bottom: 0 !important;
}

.alert-ManagerBlock .table td { /*166982 и.9108*/
    border: none !important;
}

#contacts-modal .alert-ManagerBlock { /*166982 и.9108*/
    border: 1px solid #D8ECEE;/* !important;*//*[206366 и.12183]*/
    margin-bottom: 10px;
    margin-right: 0;
    padding-left: 5px;/* !important;*/ /*[206366 и.12183]*/
    padding-right: 5px;/* !important;*/ /*[206366 и.12183]*/
    width: 100%;
}

#contacts-modal .nameManager { /*166982 и.9108*/
    padding-left: 4px;
}

#contacts-modal .row { /*166982 и.9108*/
    margin: 20px 0 0 0;
}

#contacts-modal .row > div { /*166982 и.9108*/
    padding-right: 5px;
    padding-left: 5px;
}

#contacts-modal table.managerContacts a.link { /*166982 и.9108*/
    font-size: 16px !important;
}

.modal-open #contacts-modal.modal { /*166982 и.9108*/
    overflow-x: auto !important;
}

.cooperationLink { /*166982 и.9108*/
    font-size: 14px !important;
}

/* 98942 2970 begin */

@media (min-width: 768px){
    /*.container*/#formReg {
    width: 750px;
    width: 832px;
}
}
@media (min-width: 992px){
    /*.container*/#formReg {
    width: 970px;
    width: 1022px;
}
}
@media (min-width: 1200px){
    /*.container*/#formReg {
    width: 1170px;
    width: 1252px;
}
}

#userProfile input.userField {
    width: 358px;
}

#userProfile select.userField {
    width: 356px;
}

#userProfile .group-confirm {
    margin: 0 0 10px 0;
}

#formReg .group-confirm {
    margin: 10px auto 0px;
}

.group-confirm {
    padding: 10px !important;
    border: 1px solid transparent;
    border-radius: 4px;
    border-color: #CCC !important;
}

#userProfile .group-confirm {
    /*width: 358px;*/
    width: 405px;
    margin: 3px 0 3px 0;
}

#userProfile .input-group { /*107756 4098*/
    width: 405px;
}

#formReg p.control-label[for=phone] {/*107756 4098*/
    padding-top: 32px;
}

.group-confirm .alert {
    padding: 10px;
    text-align: justify;
    display: none;
}

#userProfile .group-confirm .alert.alert-danger {
    margin: 18px auto 0px;
}

.group-confirm .alert.alert-danger-1 {
    margin: 18px auto 0px;
    background-color: #f2dede;
    color: #a94442;
    border-color: #dca7a7;
}

.group-confirm .alert .close{
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
    margin-left: 4px;
    text-decoration: none !important;
}

.group-confirm .loader {
    margin-left: 125px;
    left: 0;
    display: none;
}

#formReg .sign-verify { /*176396 и.10163*/
    position: absolute;
    font-size: 16px;
    right: 25px;
    top: 10px;
}

#formReg .input-group .sign-verify { /*176396 и.10163*/
    right: 10px !important;
    z-index: 2;
}

#formReg .input-group #pass + .sign-verify,
#formReg .input-group #confirm_password + .sign-verify {
    right: 50px !important;
}

#formReg .glyphicon-ok.sign-verify { /*176396 и.10163*/
    color: green;
}

#formReg .glyphicon-exclamation-sign.sign-verify { /*176396 и.10163*/
    color: red;
}

#userProfile .code_confirm {
    color: #000;
    width: 68px;
}

#userProfile .success-confirm {
    color: green;
    font-size: 16px;
    float: right;
    position: relative;
    float: left;
    left: 375px;
    top: -25px;
    z-index: 10;
}

/* P: 3977 H: 107270 an.gerasimov 19.12.15 */
.div-confirm-phone div,
.div-confirm-email div,
.div-confirm-login div {
    padding-right: 119px;
    text-align: justify;
}

/* P: 3977 H: 107270 an.gerasimov 19.12.15 */
#userProfile .div-confirm-phone div,
#userProfile .div-confirm-email div,
#userProfile .div-confirm-login div {
    width: 360px;   /* P: 4034 H: 107854 an.gerasimov 24.01.16 */
}

/* P: 3977 H: 107270 an.gerasimov 19.12.15 */
.div-confirm-phone button,
.div-confirm-email button,
.div-confirm-login button {
    float: right;
    /*margin-top: -35px;*/
}

#formReg .div-confirm-phone button,
#formReg .div-confirm-email button {
    padding: 6px 10px !important;
    /*width: 110px;*/ /*206366 и.12183*/
    margin-left: 10px; /*206366 и.12183*/
}

#userProfile .div-confirm-phone button,
#userProfile .div-confirm-email button {
    top: 0px;
    position: relative;
    margin-left: 10px; /*206366 и.12183*/
}

.form-confirm {
    text-align: right;
    margin: 15px 0 5px 0px;
    display: none;
}

.form-confirm div {
    display: inline-block;
}

.form-confirm input {
    width: 75px;
    display: inline-block;
    margin: auto 10px !important;
}

.form-confirm button {
    float: right;
}

/* TODO - Не опциональные селекторы */
/*#formReg .popover,*/
/*#userProfile .popover {
    background-color: #f2dede;
    border-color: #dca7a7;
    color: #a94442;
    border-radius: 4px;
}

#userProfile .popover {
    width: 358px;
    min-width: 358px !important;
    max-width: 358px;
}

#formReg .input-group .popover {
    min-width: 358px;
}

#formReg .popover {
    min-width: 397px;
}

#userProfile .popover.top .arrow {
    border-top-color: transparent;
}

#formReg .popover.top .arrow{
    border-top-color: #dca7a7;
}

#userProfile .popover.top>.arrow:after,
#formReg .popover.top>.arrow:after {
    border-top-color: #f2dede;
}*/

#formReg div.edit,
#userProfile a.edit {
    position: relative;
    display: none;
}

#formReg div.edit {
    float: right;
    right: 3px;
}

#userProfile a.edit {
    float: left;
    margin-left: 395px;
    margin-top: -25px; /*206366 и.12183*/
    line-height: 14px; /*206366 и.12183*/
    position: absolute; /* P: 4034 H: 107854 an.gerasimov 24.01.16 */
}

#formReg input.alert-danger:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 24px #e7c3c3 inset;
}

#formReg input[disabled],
#userProfile input[disabled] {
    /*cursor: default !important;*/
    cursor: not-allowed !important;
}

#userProfile input.disabled {
    cursor: not-allowed;
    background-color: #eee;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    webkit-transition: border linear .2s,box-shadow linear .2s;
    -moz-transition: border linear .2s,box-shadow linear .2s;
    -o-transition: border linear .2s,box-shadow linear .2s;
    transition: border linear .2s,box-shadow linear .2s;
}

p[for="captcha"] {
    margin-top: 55px !important;
}

/*[105150 3730]#code-captcha {
    height: 50px;
}*/

#formReg #captcha {
    width: 80px;
    margin-top: 5px;
}

#formReg #refresh_captcha {
    font-size: 17px;
    font-weight: bold;
    color: #333333; /*206366 и.12183*/
    top: 5px;
    left: 3px;
    cursor: pointer;
}

#formReg #loader-refresh {
    height: 22px;
    display: none;
}

/* 98942 2970 end */

/* н 71114 begin */
#modalBoxMess {
    position: fixed;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background: #fff;
    z-index: 2048;
    padding: 10px;
    behavior: url(/design/css/PIE/PIE.php);
    display: none;
    width: auto;
    height: auto;
}
.modalBox a.close:hover {
    background: url('/design/img/close-hover.png') left top no-repeat;
}
#modalBoxMess a.close:active, #modalBoxMess a.close:link, #modalBox a.close:visited {
    background: url('/design/img/close-default.png') left top no-repeat;
    width: 25px;
    height: 25px;
    position: absolute;
    z-index: 4096;
    right: 10px;
    top: 5px;
    padding: 0;
}
#modalBoxMess .header{
    height: 25px;
}
#modalBoxMess h1 {
    margin-top: 0;
    font: bold 16px arial;
    color: #666;
}
#modalBoxMess .content {
    margin-bottom: 10px;
}
#modalBoxMess .body {
    width: 355px;
    padding: 5px;
    text-align: justify;
}
#modalBoxMess .footer {
    bottom: 10px;
    /* right: 10px; */
    /*position: absolute;*/
    padding: 5px 5px 5px 3px;
}

#modalBoxMess button#repeat {
    margin-right: 0;
}

#modalBoxMess button {
    margin-right: 0;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    color: #FFF;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    border: 1px solid #CCC;
    border-bottom-color: #B3B3B3;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    display: inline-block;
    padding: 4px 10px 4px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    vertical-align: middle;
    background-color: #0074CC;
    background-image: -moz-linear-gradient(top, #0088cc, #0055cc);
    background-image: -ms-linear-gradient(top, #0088cc, #0055cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08C), to(#05C));
    background-image: -webkit-linear-gradient(top, #08C, #05C);
    background-image: -o-linear-gradient(top, #0088cc, #0055cc);
    background-image: linear-gradient(top, #0088cc, #0055cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0055cc', GradientType=0);
    border-color: #05C #05C #003580;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}

#overlayMess {
    background: url('/design/img/colorbox/overlay.png') repeat 0 0;
    position: fixed;
    _position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 200;
    display: none;
}
/* н 71114 end */

/* 1511 */
.yellow-message {
    top: 37px;
    left: 151px;
    display: block;
    position: absolute;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 1010;
    max-width: 276px;
    padding: 9px 14px;
    text-align: left;
    background-color: #FFF3B0;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    white-space: normal;
}

/* н 44652 begin */
div#modalBox div#add-to-basket-modal {
    background: url(/build/images/in-basket_2.1cd08cc6.png) left top no-repeat;
    background-position: 10px 12px;
    background-size: 20px;
    background-color: #FFF7C6;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -khtml-border-radius: 8px;
    border-radius: 8px;
    behavior: url(/design/css/PIE/PIE.php);
    color: #000;
    height: 24px;
    line-height: 25px;
    /*padding: 2px 10px 2px 35px;*/
    margin: 0 0 0 8px;
    text-decoration: none;
    display: inline-block !important;
    position: absolute;
    z-index: 4096;
    right: 15px;
    top: 70px;
    font-family: arial !important;
    font-size: 15px;
    font-weight: bold;
    color: #003399;
    padding: 10px 27px 10px 40px;
}

div#modalBox a.add-modal span {
    border-bottom: dotted 1px #666;
}
a#add-to-basket-modal:hover { color: red !important; }
/* н 44652 end */

#overlay {
    background: url('/design/img/colorbox/overlay.png') repeat 0 0;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 200;
    display: none;
}

/* н 71114 begin */
#modalBoxMess {
    position: fixed;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background: #fff;
    z-index: 2048;
    padding: 10px;
    behavior: url(/design/css/PIE/PIE.php);
    display: none;
    width: auto;
    height: auto;
}
.modalBox a.close:hover {
    background: url('/design/img/close-hover.png') left top no-repeat;
}
#modalBoxMess a.close:active, #modalBoxMess a.close:link, #modalBox a.close:visited {
    background: url('/design/img/close-default.png') left top no-repeat;
    width: 25px;
    height: 25px;
    position: absolute;
    z-index: 4096;
    right: 10px;
    top: 5px;
    padding: 0;
}
#modalBoxMess .header {
    height: 25px;
}
#modalBoxMess h1 {
    margin-top: 0;
    font: bold 16px arial;
    color: #666;
}
#modalBoxMess .content {
    margin-bottom: 10px;
}
#modalBoxMess .body {
    width: 355px;
    padding: 5px;
    text-align: justify;
}
#modalBoxMess .footer {
    bottom: 10px;
    /* right: 10px; */
    /*position: absolute;*/
    padding: 5px 5px 5px 3px;
}

#modalBoxMess button#repeat {
    margin-right: 0;
}
#overlayMess {
    background: url('/design/img/colorbox/overlay.png') repeat 0 0;
    position: fixed;
    _position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 200;
    display: none;
}
/* н 71114 end */

#modalBox {
    position: fixed;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background: #fff;
    z-index: 2048;
    padding: 10px;
    behavior: url(/design/css/PIE/PIE.php);
    display: none;
    /*display: inline-block;

    vertical-align: middle;
    zoom: 1;
    *display: inline;*/
    /*width: 350px;*/
    width: auto; /*380px;*/ /*н 28914*/
}

#modalBox a.close:active, #modalBox a.close:link, #modalBox a.close:visited {
    background: url('/design/img/close-default.png') left top no-repeat;
    width: 25px;
    height: 25px;
    position: absolute;
    z-index: 4096;
    right: 15px;
    top: 5px;
    padding: 0;
}

#modalBox h1 {
    margin-top: 0;
    font: bold 16px arial;
    color: #666;
}

#modalBox a.close:hover {
    background: url('/design/img/close-hover.png') left top no-repeat;
}

#modalBox div.header {
    /*margin-top: 25px;*/
}

span.content-loader {
    background: url(/build/images/ajax-loader.e87bbf8c.gif) left top no-repeat;
    position: relative;
    color: #000;
    display: inline-block;
    vertical-align: middle;
    zoom: 1;
    padding: 6px 0 0 40px;
    height: 32px;
    /*left: 50%;*/
    /*margin: 12px 0 0 -47px;*/
    left: 0; /*206366 и.12183*/
    margin: 20px 0 0 0; /*206366 и.12183*/
}

a.dotted-blue:link, a.dotted-blue:active, a.dotted-blue:visited {
    font-size: 1em;
    color: #003399;
    text-decoration: none;
    border-bottom: 1px dotted #003399;
}

a.dotted:link, a.dotted:active, a.dotted:visited {
    color: #003399;
    text-decoration: none;
    border-bottom: 1px dotted #000;
}

a.dotted-blue:hover {
    color: #ff6600;
    border-bottom: 1px dotted #ff6600;
}

a.dotted:hover {
    color: #ff6600;
    border-bottom: 1px dotted #ff6600;
}

.justify_message {
    text-align: justify !important;
}

#noty_topRight_layout_container, #noty_topRight_layout_container * {
    width: auto !important;
}

#noty_topRight_layout_container .noty_message {
    max-width: 600px;
}

/* H: 113623 */
#noty_topRight_layout_container.i-am-new {
    top:    40px !important;
    right:  47px !important;
}

/*** bootstrap-glyphicon ***/

@font-face {
    font-family: 'Glyphicons Halflings';
    src: url(/build/fonts/glyphicons-halflings-regular.2810d2a4.eot);
    src: url(/build/fonts/glyphicons-halflings-regular.2810d2a4.eot?#iefix) format('embedded-opentype'), url(/build/fonts/glyphicons-halflings-regular.e3cab037.woff) format('woff'), url(/build/fonts/glyphicons-halflings-regular.b7c24301.ttf) format('truetype'), url(/build/images/glyphicons-halflings-regular.da8dea7a.svg#glyphicons_halflingsregular) format('svg')
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.glyphicon-asterisk:before {
    content: "\2a"
}

.glyphicon-plus:before {
    content: "\2b"
}

.glyphicon-euro:before {
    content: "\20ac"
}

.glyphicon-minus:before {
    content: "\2212"
}

.glyphicon-cloud:before {
    content: "\2601"
}

.glyphicon-envelope:before {
    content: "\2709"
}

.glyphicon-pencil:before {
    content: "\270f"
}

.glyphicon-glass:before {
    content: "\e001"
}

.glyphicon-music:before {
    content: "\e002"
}

.glyphicon-search:before {
    content: "\e003"
}

.glyphicon-heart:before {
    content: "\e005"
}

.glyphicon-star:before {
    content: "\e006"
}

.glyphicon-star-empty:before {
    content: "\e007"
}

.glyphicon-user:before {
    content: "\e008"
}

.glyphicon-film:before {
    content: "\e009"
}

.glyphicon-th-large:before {
    content: "\e010"
}

.glyphicon-th:before {
    content: "\e011"
}

.glyphicon-th-list:before {
    content: "\e012"
}

.glyphicon-ok:before {
    content: "\e013"
}

.glyphicon-remove:before {
    content: "\e014"
}

.glyphicon-zoom-in:before {
    content: "\e015"
}

.glyphicon-zoom-out:before {
    content: "\e016"
}

.glyphicon-off:before {
    content: "\e017"
}

.glyphicon-signal:before {
    content: "\e018"
}

.glyphicon-cog:before {
    content: "\e019"
}

.glyphicon-trash:before {
    content: "\e020"
}

.glyphicon-home:before {
    content: "\e021"
}

.glyphicon-file:before {
    content: "\e022"
}

.glyphicon-time:before {
    content: "\e023"
}

.glyphicon-road:before {
    content: "\e024"
}

.glyphicon-download-alt:before {
    content: "\e025"
}

.glyphicon-download:before {
    content: "\e026"
}

.glyphicon-upload:before {
    content: "\e027"
}

.glyphicon-inbox:before {
    content: "\e028"
}

.glyphicon-play-circle:before {
    content: "\e029"
}

.glyphicon-repeat:before {
    content: "\e030"
}

.glyphicon-refresh:before {
    content: "\e031"
}

.glyphicon-list-alt:before {
    content: "\e032"
}

.glyphicon-lock:before {
    content: "\e033"
}

.glyphicon-flag:before {
    content: "\e034"
}

.glyphicon-headphones:before {
    content: "\e035"
}

.glyphicon-volume-off:before {
    content: "\e036"
}

.glyphicon-volume-down:before {
    content: "\e037"
}

.glyphicon-volume-up:before {
    content: "\e038"
}

.glyphicon-qrcode:before {
    content: "\e039"
}

.glyphicon-barcode:before {
    content: "\e040"
}

.glyphicon-tag:before {
    content: "\e041"
}

.glyphicon-tags:before {
    content: "\e042"
}

.glyphicon-book:before {
    content: "\e043"
}

.glyphicon-bookmark:before {
    content: "\e044"
}

.glyphicon-print:before {
    content: "\e045"
}

.glyphicon-camera:before {
    content: "\e046"
}

.glyphicon-font:before {
    content: "\e047"
}

.glyphicon-bold:before {
    content: "\e048"
}

.glyphicon-italic:before {
    content: "\e049"
}

.glyphicon-text-height:before {
    content: "\e050"
}

.glyphicon-text-width:before {
    content: "\e051"
}

.glyphicon-align-left:before {
    content: "\e052"
}

.glyphicon-align-center:before {
    content: "\e053"
}

.glyphicon-align-right:before {
    content: "\e054"
}

.glyphicon-align-justify:before {
    content: "\e055"
}

.glyphicon-list:before {
    content: "\e056"
}

.glyphicon-indent-left:before {
    content: "\e057"
}

.glyphicon-indent-right:before {
    content: "\e058"
}

.glyphicon-facetime-video:before {
    content: "\e059"
}

.glyphicon-picture:before {
    content: "\e060"
}

.glyphicon-map-marker:before {
    content: "\e062"
}

.glyphicon-adjust:before {
    content: "\e063"
}

.glyphicon-tint:before {
    content: "\e064"
}

.glyphicon-edit:before {
    content: "\e065"
}

.glyphicon-share:before {
    content: "\e066"
}

.glyphicon-check:before {
    content: "\e067"
}

.glyphicon-move:before {
    content: "\e068"
}

.glyphicon-step-backward:before {
    content: "\e069"
}

.glyphicon-fast-backward:before {
    content: "\e070"
}

.glyphicon-backward:before {
    content: "\e071"
}

.glyphicon-play:before {
    content: "\e072"
}

.glyphicon-pause:before {
    content: "\e073"
}

.glyphicon-stop:before {
    content: "\e074"
}

.glyphicon-forward:before {
    content: "\e075"
}

.glyphicon-fast-forward:before {
    content: "\e076"
}

.glyphicon-step-forward:before {
    content: "\e077"
}

.glyphicon-eject:before {
    content: "\e078"
}

.glyphicon-chevron-left:before {
    content: "\e079"
}

.glyphicon-chevron-right:before {
    content: "\e080"
}

.glyphicon-plus-sign:before {
    content: "\e081"
}

.glyphicon-minus-sign:before {
    content: "\e082"
}

.glyphicon-remove-sign:before {
    content: "\e083"
}

.glyphicon-ok-sign:before {
    content: "\e084"
}

.glyphicon-question-sign:before {
    content: "\e085"
}

.glyphicon-info-sign:before {
    content: "\e086"
}

.glyphicon-screenshot:before {
    content: "\e087"
}

.glyphicon-remove-circle:before {
    content: "\e088"
}

.glyphicon-ok-circle:before {
    content: "\e089"
}

.glyphicon-ban-circle:before {
    content: "\e090"
}

.glyphicon-arrow-left:before {
    content: "\e091"
}

.glyphicon-arrow-right:before {
    content: "\e092"
}

.glyphicon-arrow-up:before {
    content: "\e093"
}

.glyphicon-arrow-down:before {
    content: "\e094"
}

.glyphicon-share-alt:before {
    content: "\e095"
}

.glyphicon-resize-full:before {
    content: "\e096"
}

.glyphicon-resize-small:before {
    content: "\e097"
}

.glyphicon-exclamation-sign:before {
    content: "\e101"
}

.glyphicon-gift:before {
    content: "\e102"
}

.glyphicon-leaf:before {
    content: "\e103"
}

.glyphicon-fire:before {
    content: "\e104"
}

.glyphicon-eye-open:before {
    content: "\e105"
}

.glyphicon-eye-close:before {
    content: "\e106"
}

.glyphicon-warning-sign:before {
    content: "\e107"
}

.glyphicon-plane:before {
    content: "\e108"
}

.glyphicon-calendar:before {
    content: "\e109"
}

.glyphicon-random:before {
    content: "\e110"
}

.glyphicon-comment:before {
    content: "\e111"
}

.glyphicon-magnet:before {
    content: "\e112"
}

.glyphicon-chevron-up:before {
    content: "\e113"
}

.glyphicon-chevron-down:before {
    content: "\e114"
}

.glyphicon-retweet:before {
    content: "\e115"
}

.glyphicon-shopping-cart:before {
    content: "\e116"
}

.glyphicon-folder-close:before {
    content: "\e117"
}

.glyphicon-folder-open:before {
    content: "\e118"
}

.glyphicon-resize-vertical:before {
    content: "\e119"
}

.glyphicon-resize-horizontal:before {
    content: "\e120"
}

.glyphicon-hdd:before {
    content: "\e121"
}

.glyphicon-bullhorn:before {
    content: "\e122"
}

.glyphicon-bell:before {
    content: "\e123"
}

.glyphicon-certificate:before {
    content: "\e124"
}

.glyphicon-thumbs-up:before {
    content: "\e125"
}

.glyphicon-thumbs-down:before {
    content: "\e126"
}

.glyphicon-hand-right:before {
    content: "\e127"
}

.glyphicon-hand-left:before {
    content: "\e128"
}

.glyphicon-hand-up:before {
    content: "\e129"
}

.glyphicon-hand-down:before {
    content: "\e130"
}

.glyphicon-circle-arrow-right:before {
    content: "\e131"
}

.glyphicon-circle-arrow-left:before {
    content: "\e132"
}

.glyphicon-circle-arrow-up:before {
    content: "\e133"
}

.glyphicon-circle-arrow-down:before {
    content: "\e134"
}

.glyphicon-globe:before {
    content: "\e135"
}

.glyphicon-wrench:before {
    content: "\e136"
}

.glyphicon-tasks:before {
    content: "\e137"
}

.glyphicon-filter:before {
    content: "\e138"
}

.glyphicon-briefcase:before {
    content: "\e139"
}

.glyphicon-fullscreen:before {
    content: "\e140"
}

.glyphicon-dashboard:before {
    content: "\e141"
}

.glyphicon-paperclip:before {
    content: "\e142"
}

.glyphicon-heart-empty:before {
    content: "\e143"
}

.glyphicon-link:before {
    content: "\e144"
}

.glyphicon-phone:before {
    content: "\e145"
}

.glyphicon-pushpin:before {
    content: "\e146"
}

.glyphicon-usd:before {
    content: "\e148"
}

.glyphicon-gbp:before {
    content: "\e149"
}

.glyphicon-sort:before {
    content: "\e150"
}

.glyphicon-sort-by-alphabet:before {
    content: "\e151"
}

.glyphicon-sort-by-alphabet-alt:before {
    content: "\e152"
}

.glyphicon-sort-by-order:before {
    content: "\e153"
}

.glyphicon-sort-by-order-alt:before {
    content: "\e154"
}

.glyphicon-sort-by-attributes:before {
    content: "\e155"
}

.glyphicon-sort-by-attributes-alt:before {
    content: "\e156"
}

.glyphicon-unchecked:before {
    content: "\e157"
}

.glyphicon-expand:before {
    content: "\e158"
}

.glyphicon-collapse-down:before {
    content: "\e159"
}

.glyphicon-collapse-up:before {
    content: "\e160"
}

.glyphicon-log-in:before {
    content: "\e161"
}

.glyphicon-flash:before {
    content: "\e162"
}

.glyphicon-log-out:before {
    content: "\e163"
}

.glyphicon-new-window:before {
    content: "\e164"
}

.glyphicon-record:before {
    content: "\e165"
}

.glyphicon-save:before {
    content: "\e166"
}

.glyphicon-open:before {
    content: "\e167"
}

.glyphicon-saved:before {
    content: "\e168"
}

.glyphicon-import:before {
    content: "\e169"
}

.glyphicon-export:before {
    content: "\e170"
}

.glyphicon-send:before {
    content: "\e171"
}

.glyphicon-floppy-disk:before {
    content: "\e172"
}

.glyphicon-floppy-saved:before {
    content: "\e173"
}

.glyphicon-floppy-remove:before {
    content: "\e174"
}

.glyphicon-floppy-save:before {
    content: "\e175"
}

.glyphicon-floppy-open:before {
    content: "\e176"
}

.glyphicon-credit-card:before {
    content: "\e177"
}

.glyphicon-transfer:before {
    content: "\e178"
}

.glyphicon-cutlery:before {
    content: "\e179"
}

.glyphicon-header:before {
    content: "\e180"
}

.glyphicon-compressed:before {
    content: "\e181"
}

.glyphicon-earphone:before {
    content: "\e182"
}

.glyphicon-phone-alt:before {
    content: "\e183"
}

.glyphicon-tower:before {
    content: "\e184"
}

.glyphicon-stats:before {
    content: "\e185"
}

.glyphicon-sd-video:before {
    content: "\e186"
}

.glyphicon-hd-video:before {
    content: "\e187"
}

.glyphicon-subtitles:before {
    content: "\e188"
}

.glyphicon-sound-stereo:before {
    content: "\e189"
}

.glyphicon-sound-dolby:before {
    content: "\e190"
}

.glyphicon-sound-5-1:before {
    content: "\e191"
}

.glyphicon-sound-6-1:before {
    content: "\e192"
}

.glyphicon-sound-7-1:before {
    content: "\e193"
}

.glyphicon-copyright-mark:before {
    content: "\e194"
}

.glyphicon-registration-mark:before {
    content: "\e195"
}

.glyphicon-cloud-download:before {
    content: "\e197"
}

.glyphicon-cloud-upload:before {
    content: "\e198"
}

.glyphicon-tree-conifer:before {
    content: "\e199"
}

.glyphicon-tree-deciduous:before {
    content: "\e200"
}

/*** bootstrap-glyphicon end***/

div.popover {
    max-width: 500px;
}

.left_cart {
    float: left;
    width: 9px;
    height: 32px;
    background: url(/design/img/cart/left_cart.png);
}

.right_cart {
    float: left;
    width: 9px;
    height: 32px;
    background: url(/design/img/cart/right_cart.png);
}

.bg_cart {
    float: left;
    height: 32px;
    background: url(/design/img/cart/bg_cart.png) repeat-x;
    color: #000;
    font-size: 14px;
    padding: 5.5px 0;
    font-weight: bold;
}

.cart {
    float: left;
    width: 34px;
    height: 32px;
    background: url(/design/img/cart/cart.png);
    cursor: pointer;
}

.flname1 {
    /*height: 37px;*/
    border-bottom: 1px solid #8d8d8d;
}

.flname2 {
    border-bottom: 1px solid #8d8d8d;
}

.tableDocth {
    font-size: 10px;
}

.tableDoctd {
    font-size: 12px;
}

div.popover {
    max-width: 500px;
}

/* H: 100666 BEGIN */
.highlight {
    padding: 9px 14px;
    background-color: #f7f7f9;
    border: 1px solid #e1e1e8;
    border-radius: 4px;
    display: inline-block;
}

#ie-download {
    background: url('/design/img/i-ie.png') left top no-repeat;
    width: 32px;
    height: 32px;
    _height: 32px;
    min-height: 32px;

    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    margin : 5px;
    cursor: pointer;
}

#chrome-download {
    background: url('/design/img/i-chrome.png') left top no-repeat;
    width: 32px;
    height: 32px;
    _height: 32px;
    min-height: 32px;

    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    margin : 5px;
    cursor: pointer;
}

#firefox-download {
    background: url('/design/img/i-firefox.png') left top no-repeat;
    width: 32px;
    height: 32px;
    _height: 32px;
    min-height: 32px;

    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    margin : 5px;
    cursor: pointer;
}

#safari-download {
    background: url('/design/img/i-safari.png') left top no-repeat;
    width: 32px;
    height: 32px;
    _height: 32px;
    min-height: 32px;

    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    margin : 5px;
    cursor: pointer;
}

#opera-download {
    background: url('/design/img/i-opera.png') left top no-repeat;
    width: 32px;
    height: 32px;
    _height: 32px;
    min-height: 32px;

    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    margin : 5px;
    cursor: pointer;
}
/* H: 100666 END */

#modal-news a {
    font-size: inherit;
    text-decoration: none;
    border-bottom: 1px dotted #003399;
    color: #003399;
}

.OnlineUsers{
    font-size: 14px;
    padding: 5px;
    background-image: -webkit-linear-gradient(top, #d9edf7 0, #b9def0 100%);
    background-image: linear-gradient(to bottom, #d9edf7 0, #b9def0 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffb9def0', GradientType=0);
    border-color: #9acfea;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .2);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .25), 0 1px 2px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .25), 0 1px 2px rgba(0, 0, 0, .05);
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: black;
    margin-bottom: 6px;
    border: 1px solid transparent;
    border-radius: 4px;
    width: 350px;
    margin-left: 3px;
}

.dropdown-menu.open{
    min-height: 45px !important;
}

.dropdown-menu.inner.selectpicker{
    min-height: 45px !important;
}

.classRed{
    background-color: #EF5E5E;
}

.paymentMethodsIndent{
    margin-bottom: 6px;
}

#modalFormTypePay .alert-warning{
    text-align: justify;
}

#modalConfirmOrder .alert-warning{
    text-align: justify;
}

/* P: 3586 H: 103952 an.gerasimov 31.07.15 */
img[data-src] {
    cursor: pointer;
}

/* P: 3628 H: 104431 an.gerasimov 24.08.15 */
a.link_at {
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    border-bottom: 1px dotted #003399;
    color: #003399;
}
a:hover.link_at {
    color: #ff6600;
    border-bottom: 1px dotted #ff6600;
    text-decoration: none;
}

/* P: 3157 H: 106014 an.gerasimov 28.10.15 */
.positivessl {
    bottom: 0px;
    position: absolute;
}

/* P: 3811 H: 106628 an.gerasimov 21.11.15 */
.modal#iamhuman img#fact {
    height: 60px;
}
.modal#iamhuman .modal-footer {
    margin: 0;
}
.modal#iamhuman .input-group {
    margin: 13px 0 0 0;
}

#ugFilial .hide-contacts { /*106796 3867*/
    font-size: 14px;
    cursor: pointer;
    margin-left: 15px;
    top: 4px;
}

#ugFilial .hide-contacts.glyphicon-eye-open { /*106796 3867*/
    color: #1b4f88;
}

#ugFilial .hide-contacts.glyphicon-eye-close { /*106796 3867*/
    color: grey;
}

/* P: 3977 H: 107270 an.gerasimov 19.12.15 */
.modal#confirmed_modal .modal-footer {
    margin: 0;
}

/* P: 4034 H: 107854 an.gerasimov 24.01.16 */
#userProfile #blockphoto td {
    vertical-align: top;
}
#userProfile #photomanager div:not([id=loadPhoto]) {
    margin: 10px 0 0 0;
}
#userProfile #loadPhoto {
    color: #fff !important;
    text-decoration: none !important;
}
#userProfile input[type=file] {
    cursor: pointer !important;
}

.not_enable_user {
    width: 330px !important;
}

#preLoad {
    position: absolute;
    display: none;
    background: url("/design/img/g.gif");
    text-align: center;
    z-index: 900;
}

/* P: 2440 an.gerasimov 18.09.16 */
.manager-consult {
    font-weight: bold;
}
.manager-consult a.btn {
    text-decoration: none;
}
.manager-consult .btn {
    font-weight: bold;
    max-width: 256px;
    color: #333;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.manager-consult .manager-status {
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    height: 21px;
}
.manager-consult .status-update {
    background-image: url('/design/img/ajax-loader2.gif');
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
}
.manager-consult .status-online {
    background-image: url(//consultsystems.ru/script/im/button/online.png);
}
.manager-consult .status-offline {
    background-image: url(/design/img/offline.png);
}
.manager-consult .status-unavailable {
    background-image: url(/design/img/unavailable.png);
    width: 87px;
}
.manager-consult .alert-info {
    margin: 5px 0 0 0;
    font-size: 12px;
    color: #000;
    padding: 6px;
}

/*
    H: 114146
    Для случая, когда повторно не отркывается выпадающий список.
*/
.bootstrap-select ul.dropdown-menu.inner {
    display: block;
}

.danger-message {
    text-align: center;
    margin-top: 5px;
    padding: 2px 5px !important;
    border: 1px solid transparent !important;
    border-radius: 4px !important;
    border-color: #dca7a7 !important;
    color: #000 !important;
    margin-bottom: 0 !important;
}

/* H: 115087 */
.ny_logo {
    float: left;
    position: relative;
    top: -6px;
    width: 80px;
    height: 38px;
}
.ny_snowflakes {
    float: left;
    position: relative;
    top: -5px;
    width: 244px;
    height: 38px;
}

/*p.5141*/
.text_personal_data {
    text-align: justify;
    margin-right: 10px;
}

.modal_personal_data, #modal_personal_data {
    overflow-y: visible !important;
}

.modal_personal_data .modal-body, #modal_personal_data .modal-body {
    height: 350px;
    overflow: auto;
}

.modal_personal_data .alert-message, #modal_personal_data .alert-message {
    margin-right: 10px;
}

.modal_personal_data .modal-dialog, #modal_personal_data .modal-dialog {
    width: 700px;
}

.modal_personal_data .modal-footer strong, #modal_personal_data .modal-footer strong {
    margin-right: 17px;
}

/* P: 5058 H: 123169 */
.top-sub-menu.hidden {
    display: none !important;
}

.ekko-lightbox .lightbox-btn-print {
    background: url(/design/img/colorbox/printButton.png) no-repeat;
    background-size: cover;
    width: 34px;
    height: 34px;
    text-indent: -9999px;
    margin: 0 15px 0 0;
    padding: 0;
    border: 0;
    float: left;
}
/* Fix Bootstrap v3.3.7 */
/* Cкрыть цены */
span.hide-price {
    /*height: 35px;*/
    padding: 6px 0 7px 30px;
}
span.hide-price label {
    padding: 0;/* !important;*//*206366 и.12183*/
}
/* По модели авто -> Расположение */
#params .placement .radio label {
    width: 100%;
}
#params .placement .radio label input {
    display: block;
}
/* ЛК -> Документы */
#docList .date .open-datepicker {
    padding: 6px;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    color: #FFF !important;
    background-color: #0081C2 !important;
    background-image: -webkit-linear-gradient(top, #0081C2 0%, #0081C2 100%) !important;
    background-image:      -o-linear-gradient(top, #0081C2 0%, #0081C2 100%) !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#0081C2), to(#0081C2)) !important;
    background-image:         linear-gradient(to bottom, #0081C2 0%, #0081C2 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0081c2', endColorstr='#ff0081c2', GradientType=0) !important;
}
.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active {
    color: #333 !important;
}
.btn.disabled {
    pointer-events: none;
}

.captcha img.img-captcha {
    width: 160px;
    height: 80px;
}

.captcha a.btn-refresh {
    font-size: 22px;
    vertical-align: middle;
    /*margin: 0 0 0 10px;*/
    padding: 0 0 0 5px;
}

.common-modal .captcha {
    margin: 0;
}

.common-modal .captcha div.col-sm-offset-2.col-sm-10,
.forgotPassForm .captcha div.col-sm-12 {
    padding-bottom: 15px;
}

.common-modal .captcha label,
.forgotPassForm .captcha label {
    padding-right: 0;
}

.common-modal .captcha #input-captcha {
    width: 160px;
}

.forgotPassForm .captcha label {
    padding-top: 7px;
    width: auto;
}

.profile-message-info { /*133765 5728*/
    padding: 10px 10px;
    background: #FFF7C6;
    font-size: 13px;
    margin: 5px 10px 5px 0;
    padding-left: 40px !important;
    background-image: url(/design/img/icons/warning.png);
    background-repeat: no-repeat;
    background-position: 3px center;
    background-size: 32px 32px !important;
    color: #000000;
}

.profile-message-info ul { /*133765 5728*/
    margin: 5px 0;
    padding-left: 16px;
}

.profile-message-info a { /*133765 5728*/
    font-size: 13px;
}

/*Р.5964, Н.0000139218*/
.popover-disable-firm {
    margin-bottom    : 0;
    text-align       : center;
    font-size        : 14px;
}

/*Р.5134, Н.0000140543*/
.welcome .btn.btn-default.btn-orange {
    text-decoration: aliceblue;
    background-image: -moz-linear-gradient(top, #FFD700 0, #FFA500 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFD700), color-stop(100%, #FFA500));
    background-image: -webkit-linear-gradient(top, #FFD700 0, #FFA500 100%);
    background-image: -o-linear-gradient(top, #FFD700 0, #FFA500 100%);
    background-image: -ms-linear-gradient(top, #FFD700 0, #FFA500 100%);
    background-image: linear-gradient(top, #FFD700 0, #FFA500 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFD700', endColorstr='#FFA500');
    -pie-background: linear-gradient(#FFD700, #FFA500);
    text-shadow: 0 1px 0 #FFD700;
    color: black;
}

.welcome .btn.btn-default.btn-orange:focus, .welcome .btn.btn-default.btn-orange:active, .welcome .btn.btn-default.btn-orange:hover {
    background-color: #FFA500;
}

/*Р.6001 Н.141819*/
.vertical-alignment-helper {
    display:table;
    height: 100%;
    width: 100%;
    pointer-events:none;
}
.vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
    pointer-events:none;
}
.modal-content-center {
    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
    width:inherit;
    max-width:inherit; /* For Bootstrap 4 - to avoid the modal window stretching full width */
    height:inherit;
    /* To center horizontally */
    margin: 0 auto;
    pointer-events:all;
}

.ekko-lightbox .ekko-lightbox-nav-overlay a {
    color: #333 !important;
    opacity: 1 !important;
}

.ekko-lightbox .ekko-lightbox-item.fade.in.show {
    padding: 0 30px;
}

.ekko-lightbox .ekko-lightbox-nav-overlay a span {
    padding: 0 !important;
}

div[data-toggle="lightbox"][data-remote] {
    display: none;
}

.ekko-lightbox .ekko-lightbox-item.fade:not(.show) .img-fluid {
    display: none;
}

#basketNew .popover {
    z-index: 999 !important;
}

/*[изм.9741 н.0000172194]*/
#basketNew .popover:hover {
    z-index: 1010 !important;
}

.icon-question {
    background-image: url('/design/img/icons/question.png');
    width: 20px;
    height: 20px;
    display: inline-block;
}

.datepicker-delivery .datepicker .datepicker-days .day:not(.disabled):not(.active) {
    border: solid 1px #4df542;
    background: #4df54240;
}

.rotate-180 {
    transform: rotate(180deg);
}

/*[изм.8969 н.0000170207]*/
#img_retail, #img_wholesale {
    width: 100% !important;
}

.welcome-new .panel {
    background-color: #0f539a;
    border-radius: 20px;
}

.welcome-new .panel-body {
    padding: 10px !important;
}

.welcome-new .welcome-title {
    padding: 5px;
    background-color: #FFCB6C;
    text-align: center;
    border-radius: 10px;
}

.welcome-new .welcome-title b {
    font-size: larger;
}

.welcome-new a, .welcome-new a:hover, .welcome-new a:focus {
    text-decoration: none !important;
}

.welcome-new .page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px 0; /*206366 и.12183*/
    border-bottom: 1px solid #eee;
}

.welcome-new .welcome-sub-title-xs {
    background-color: #FFCB6C;
    text-align: center;
    /*height: 148px;*/
    padding: 20px;
    /*margin: 50px 0px 30px 0px;*/
    font-size: 35px;
    color: #0f539a;
}

@media (min-width: 768px) {
    .welcome-new .welcome-sub-title-sm {
        background-color: #FFCB6C;
        text-align: center;
        /*height: 148px;*/
        padding: 20px;
        /*margin: 50px 0px 30px 0px;*/
        font-size: 35px;
        color: #0f539a;
    }
}

@media (min-width: 992px) {
    .welcome-new .welcome-sub-title-md {
        background-color: #FFCB6C;
        text-align: center;
        /*height: 148px;*/
        padding: 20px;
        /*margin: 50px 0px 30px 0px;*/
        font-size: 30px;
        color: #0f539a;
        height: 100px;
    }
}

@media (min-width: 1200px) {
    .welcome-new .welcome-sub-title-lg {
        background-color: #FFCB6C;
        text-align: center;
        /*height: 148px;*/
        padding: 20px;
        /*margin: 50px 0px 30px 0px;*/
        font-size: 39px;
        color: #0f539a;
        height: 125px;
    }

    .welcome-new .p_retail {
        margin: 2px 0 25px 0;
    }
}

.alert-errors { /*171568 и.9108*/
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
    display: none;
}

#profile-message { /*171568 и.9108*/
    color: #3caa3c;
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
}

.alert-errors, #profile-message, #profile-message-info, .errMsg { /*171568 и.9108*/
    margin-bottom: 10px;
}

.main-api-service-access-status {
    height: 13px;
    width: 13px;
    border-radius: 50%;
    display: inline-block;
    top: 1px;
    position: relative;
    background-color: #fff;
}

.main-api-service-access-status.on {
    background-color: #5cb85c;
}

.main-api-service-access-status.off {
    background-color: #d9534f;
}

#basketBlocks .panel-title > .icon-question {
    margin-bottom: -5px;
}

.align-middle {
    vertical-align: middle !important;
}

#modalTechInfo #ti-nom-title {
    color: #003399;
}

#addToBasketTabs > ul > li > a, #techInfoTabs > ul > li > a, #modalTechInfo ul.nav.nav-tabs li a {
    font-size: 14px;
    font-weight: bold;
}

#modalTechInfo ul.nav.nav-tabs li a,
#modalTechInfo ul.nav.nav-tabs li a:hover {
    border: solid 1px;
    border-color: #e1e1e8;/* !important;*//*206366 и.12183*/
    background-image: -webkit-linear-gradient(top, #F9F9F9 0, #EEEEF7 100%);/* !important;*/
    background-color: rgba(165, 204, 255, 0.5);
    background-image: -moz-linear-gradient(top, #F9F9F9 0, #EEEEF7 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #F9F9F9, color-stop(100%, #EEEEF7)));
    background-image: -webkit-linear-gradient(top, #F9F9F9 0, #EEEEF7 100%);
    background-image: -o-linear-gradient(top, #F9F9F9 0, #EEEEF7 100%);
    background-image: -ms-linear-gradient(top, #F9F9F9 0, #EEEEF7 100%);
    background-image: linear-gradient(to bottom, #F9F9F9 0, #EEEEF7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F9F9F9', endColorstr='#EEEEF7');
    -pie-background: linear-gradient(#F9F9F9, #EEEEF7);
    background-repeat: repeat-x;
    behavior: url(/design/css/PIE/PIE.php);
}

#modalTechInfo ul.nav.nav-tabs li:not(.active) a:hover {
    color: black;
}

#modalTechInfo ul.nav.nav-tabs li.active a,
#modalTechInfo ul.nav.nav-tabs li.active a:hover {
    background-color: #428bca;/* !important;*//*206366 и.12183*/
    background-image: -moz-linear-gradient(top, #428bca 0, #2d6ca2 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #428bca), color-stop(100%, #2d6ca2));
    background-image: -webkit-linear-gradient(top, #428bca 0, #2d6ca2 100%);
    background-image: -o-linear-gradient(top, #428bca 0, #2d6ca2 100%);
    background-image: -ms-linear-gradient(top, #428bca 0, #2d6ca2 100%);
    background-image: linear-gradient(to bottom, #428bca 0, #2d6ca2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#428bca', endColorstr='#2d6ca2');
    -pie-background: linear-gradient(#428bca, #2d6ca2);
    background-repeat: repeat-x;
    border-color: #2d6ca2;/* !important;*/
    color: white;/* !important;*/
}

#modalTechInfo {
    overflow-y: auto !important;
}

#modalTechInfo .modal-dialog {
    margin-bottom: 10px !important;
}

#modalTechInfo a.link_at {
    font-weight: bold;
}

#findAll {
    margin-left: 15px;
    margin-top: 10px;
}

#ApplicabilityInfo,
#CrossReplaceInfo > div:first-child {
    max-height: 300px;
    overflow-y: auto;
}

#problems, #textNotice {
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    box-shadow: 0 1px 1px #DDDDDD inset, 0 1px 0 #FFFFFF;
    float: left;
    padding: 5px;
    width: 400px;
    color: #000000;
    background-color: #ffffff;
    transition: all 1s ease 0s;
}

#ReportProblems .bootstrap-select.btn-group, #textNotice, #ReportProblems table small, #sendNotice {
    margin: auto 6px;
}

#ReportProblems .message-info {
    text-align: justify;
    padding: 0 3px 10px 10px; /*206366 и.12183*/
}

#ReportProblems .bootstrap-select {
    width: 400px !important;
}

#ReportProblems .bootstrap-select.btn-group .dropdown-menu li > a {
    font-size: 14px;
}

#ReportProblems ul.dropdown-menu.selectpicker > li > a:hover {
    background-image: -webkit-linear-gradient(top, #0081C2 0, #0081C2 100%);
    background-image: linear-gradient(to bottom, #0081C2 0, #0081C2 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0081C2', endColorstr='#0081C2', GradientType=0);
    background-color: #0081C2;
    color: #FFFFFF;
}

#ReportProblems table {
    border-collapse: separate;
    border-spacing: 8px;
}

#ReportProblems .alert {
    display: none;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.text-dark {
    color: #333 !important;
}

.alert-warning-icon {
    padding-left: 40px !important;
    background-position-x: 3px !important;
    background-position-y: center !important;
    background-repeat: no-repeat !important;
    background-image: url('/design/img/icons/warning.png') !important;
    background-size: 32px 32px !important;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.list-style-type-none {
    list-style-type: none !important;
    margin-left: -40px; /*206420 и.12356*/
}

#modalDeliveryAddress .modal-dialog.modal-lg {
    /*width: 1100px;*/
    max-width: 980px; /*206366 и.12183*/
}

#modalDeliveryAddress .modal-content {
    min-width: 430px; /*206366 и.12183*/
}

.modal-open #modalDeliveryAddress.modal {
    overflow-x: auto; /*206366 и.12183*/
    overflow-y: auto; /*206366 и.12183*/
}

@media (min-width: 992px) {
    #modalDeliveryAddress .modal-lg { /*206366 и.12183*/
        width: 940px ! important;
    }
}

#modalDeliveryAddress label {
    padding: 0; /*206366 и.12183*/
    white-space: nowrap; /*206366 и.12183*/
}

#modalDeliveryAddress .form-group {
    display: flex; /*206366 и.12183*/
    align-items: center; /*206366 и.12183*/
    justify-content: flex-end; /*206366 и.12183*/
    margin-left: 0; /*206366 и.12183*/
}

#modalDeliveryAddress span[data-toggle="popover"] {
    margin-bottom: -5px;
}

#modalDeliveryAddress .link-phone-setting {
    color: #333;
    font-size: 12px;
    text-decoration: none;
}

#modalDeliveryAddress .active .link-phone-setting,
#modalDeliveryAddress .active .link-phone-setting:hover,
#modalDeliveryAddress .active .link-phone-setting:focus {
    color: #fff;
}

#modalDeliveryAddress .additional-phone {
    display: none;
}

#modalDeliveryAddress #suggest[placeholder],
#modalDeliveryAddress #comment[placeholder] {
    color: #555;
}

#modalDeliveryAddress #helpBlockSuggest {
    display: none;
}

#modalDeliveryAddress #map {
    /*width: 1068px;*/
    width: 100%; /*206366 и.12183*/
    height: 356px;
    display: none;
    overflow: auto; /*206366 и.12183*/
}

#modalDeliveryAddress .validation-message {
    margin-bottom: 5px;
    color: #a94442;
}

#modalDeliveryAddress .dropdown-menu {
    max-height: 242px;
    overflow-y: scroll;
}

.text-red {
    color: #ff0000 !important;
}

#modal-news img,
.panel-news img {
    max-width: 500px;
}

[dir=ltr] {
    margin-right: 35px;
}

p.dotted-blue-disabled {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2px;
    color: #777;
}

p.dotted-blue {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2px;
}

#accordDelivery>.panel{
    background-color: #D5E9FF;
    border: none;
    box-shadow: none;
}

.form-danger {
    background-color: #f2dede;
    border-color: #dca7a7 !important;
}

.form-danger hr {
    border-top-color: #e4b9c0;
}

.form-danger .panel {
    background-color: #f2dede !important;
}

/* Форма обратной связи Битрикс 24 И.8252 Н.196948 */
#footer-elements a, #footer-elements a.copyright {
    font-size: inherit;
    color: #ffffff;
    text-decoration: underline;
}

#footer-elements  a:hover, #footer-elements a.copyright:hover {
    color: #fae594;
    cursor: pointer;
}

/* end */

div[name='saveCartAlert']>.alert {
    display: none;
    margin: 15px 0 0 0;
}

.has-error div[name='saveCartAlert']>.alert-danger{
    display: block !important;
}

#search-loader.infoAllItemsInBasket {
    background: url(/build/images/ajax-loader3.90672f1d.gif) left top no-repeat;
    background-size: 25px;
    height: 28px;
}

.in-progress {
    cursor: progress;
    opacity: 0.5;
}
.in-progress button, .in-progress textarea, .in-progress input {
    pointer-events: none;
    cursor: not-allowed;
}

#modalConfirmMoveItem label {
    margin: 5px 0 5px 10px;
    font-weight: normal;
}

#modalConfirmMoveItem textarea[name="newComment"] {
    margin: 5px 0 5px 0px;
    display: none;
}

.tmRight .bootstrap-select.btn-group .dropdown-toggle .filter-option {
    width: 88%;
}

#formReg .btn-telegram {
    width: 100%;
    background: #2aabee;
    border-color: #2aabee;
    color: #fff;
    text-decoration: none;
}

.icon-telegram {
    box-sizing: border-box;
    background: url(/design/img/icons/WidgetButton_LogoLarge.png) no-repeat 0 0;
    width: 28px;
    height: 28px;
    line-height: 28px;
    display: inline-block;
    vertical-align: middle;
}


.btn.btn-login {
    background-image: -webkit-linear-gradient(top, #ff8c00 0%, #eb6011 100%);
    background-image: -o-linear-gradient(top, #ff8c00 0%, #eb6011 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff8c00), to(#eb6011));
    background-image: linear-gradient(to bottom, #ff8c00 0%, #eb6011 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff265a88', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
    border-color: #ff8b00;
    font-weight: bold;
    padding: 2px 10px 2px 10px;
}

.btn.btn-login:hover {
    background-image: -webkit-linear-gradient(top, #eb6011 0%, #eb6011 100%);
    background-image: -o-linear-gradient(top, #eb6011 0%, #eb6011 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#eb6011), to(#eb6011));
    background-image: linear-gradient(to bottom, #eb6011 0%, #eb6011 100%);
    border-color: #eb6011;
    color: #f3f3f3;
}

.switch-locale {
    width: 70px !important;
    z-index: 10000; /*206366 и.12183*/
}

.switch-locale > button {
    padding-top: 4px; /*206366 и.12183*/
    padding-bottom: 4px; /*206366 и.12183*/
}

#adminMenu .link {
    font-size: 12px;
}


/**
 * и.12183
 * Политика использования COOKIE
 */

.cookie {
    position: fixed;
    width: auto;
    height: 44px;
    min-width: 800px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px 20px;
    z-index: 8000;
    display: flex;
    align-items: center;
    justify-content: center;
    /*background: #DCDDE3;*/
    /*background: #f3f1f9;*/
    background: #E6E9EE;
}

.cookie .cookie-container {
    height: 100%;
}

.cookie .cookie-content {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;

    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;

    color: #333333;
    height: 100%;
}

.cookie .cookie-body {
    padding: 0;
    margin: 0 auto;
    height: 100%;
}

.cookie .cookie-content > *:not(:first-child) {
    margin-left: 15px;
}

.cookie .cookie-content a {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-decoration: underline;
}

.cookie .cookie-content a:hover {
    color: #FF6600 !important;
}

.cookie-policy h5 {
    margin-top: 15px;
}

.cookie-policy p {
    margin-bottom: 15px;
}

.cookie-policy ul, .welcome ul, .privacy-policy ul, .delivery-policy ul,
.cookie-policy ol, .welcome ol, .privacy-policy ol, .delivery-policy ol {
    padding-left: 15px;
}

.cookie-policy ul div {
    margin-bottom: 10px;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.d-none {
    display: none;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
    justify-content: center;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: end;
}

.justify-content-between {
    justify-content: space-between;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-blue {
    color: #003399;
}

.w-100 {
    width: 100%;
}

.w-auto {
    width: auto;
}

.news {
    margin-right: 0 !important;
}

.panel-news {
    min-width: 500px;
}

.news.d-flex .col-sm-6, .news > .row > .col-sm-6, .news-search .col-sm-6 {
    min-width: 530px;
}

@media (max-width: 1090px) {
    .news.d-flex .col-sm-6, .news > .row > .col-sm-6, .news-search .col-sm-6 {
        width: 100%;
    }
}

#formReg .btn-generate-password {
    float: right;
}

@charset "UTF-8";
.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ms-0 {
  margin-left: 0;
}

.me-0 {
  margin-right: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m-auto {
  margin: auto;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.ps-0 {
  padding-left: 0;
}

.pe-0 {
  padding-right: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.ms-1 {
  margin-left: 0.25rem;
}

.me-1 {
  margin-right: 0.25rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.m-auto {
  margin: auto;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.ps-1 {
  padding-left: 0.25rem;
}

.pe-1 {
  padding-right: 0.25rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ms-2 {
  margin-left: 0.5rem;
}

.me-2 {
  margin-right: 0.5rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.m-auto {
  margin: auto;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.ps-2 {
  padding-left: 0.5rem;
}

.pe-2 {
  padding-right: 0.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.ms-3 {
  margin-left: 1rem;
}

.me-3 {
  margin-right: 1rem;
}

.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.m-auto {
  margin: auto;
}

.pt-3 {
  padding-top: 1rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.ps-3 {
  padding-left: 1rem;
}

.pe-3 {
  padding-right: 1rem;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.ms-4 {
  margin-left: 1.5rem;
}

.me-4 {
  margin-right: 1.5rem;
}

.mx-4 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.m-auto {
  margin: auto;
}

.pt-4 {
  padding-top: 1.5rem;
}

.pb-4 {
  padding-bottom: 1.5rem;
}

.ps-4 {
  padding-left: 1.5rem;
}

.pe-4 {
  padding-right: 1.5rem;
}

.px-4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.mt-5 {
  margin-top: 3rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.ms-5 {
  margin-left: 3rem;
}

.me-5 {
  margin-right: 3rem;
}

.mx-5 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.my-5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.m-auto {
  margin: auto;
}

.pt-5 {
  padding-top: 3rem;
}

.pb-5 {
  padding-bottom: 3rem;
}

.ps-5 {
  padding-left: 3rem;
}

.pe-5 {
  padding-right: 3rem;
}

.px-5 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.vlm {
  vertical-align: middle;
}

.font-weight-normal {
  font-weight: normal;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .ekko-lightbox .modal-dialog {
    flex: 100% !important;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    -ms-overflow-style: none;
  }
}

.textarea-resize-none {
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
}

.modal_personal_data {
  /*220198 и.12541*/
}

.modal_personal_data #accept {
  background: #00aeef;
  border: none;
  height: 52px;
  font-weight: 700;
}

.modal_personal_data #accept:hover {
  background-color: rgba(0, 174, 239, 0.7);
}

.modal_personal_data #not-accept {
  height: 52px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: none;
  color: #000;
  font-weight: 700;
}

.modal_personal_data .modal-footer > .row {
  margin-right: 0;
  margin-left: 0;
}

.modal_personal_data .modal-footer > .row > .col-xs-6 {
  padding: 0;
}

.modal_personal_data .modal-footer > .row > .col-xs-6:last-child {
  padding-left: 10px;
}

.b24-window-panel {
  max-width: 520px !important;
}

