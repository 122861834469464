@each $name, $width in $spacers {
  .mt-#{$name} {
    margin-top: $width;
  }

  .mb-#{$name} {
    margin-bottom: $width;
  }

  .ms-#{$name} {
    margin-left: $width;
  }

  .me-#{$name} {
    margin-right: $width;
  }

  .mx-#{$name} {
    margin-left: $width;
    margin-right: $width;
  }

  .my-#{$name} {
    margin-top: $width;
    margin-bottom: $width;
  }

  .m-auto {
    margin: auto;
  }

  .pt-#{$name} {
    padding-top: $width;
  }

  .pb-#{$name} {
    padding-bottom: $width;
  }

  .ps-#{$name} {
    padding-left: $width;
  }

  .pe-#{$name} {
    padding-right: $width;
  }

  .px-#{$name} {
    padding-left: $width;
    padding-right: $width;
  }

  .py-#{$name} {
    padding-top: $width;
    padding-bottom: $width;
  }
}

// [изм.10974 н.0000189466]
.vlm {
  vertical-align: middle;
}

.font-weight-normal {
  font-weight: normal;
}